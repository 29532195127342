import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';  // Default import
import Footer from './components/Footer';  // Default import
import Home from './pages/Home';  // Default import
import Preloader from './components/Preloader';  // Default import
import AllPages from './components/AllPages';  // Default import
import AuthPage from "./components/auth/AuthPage";
import useNavigationLoader from './hooks/useNavigationLoader';  // Default import

const AppContent = () => {
  const loading = useNavigationLoader();
  
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth-page" element={<AuthPage />} />
            <Route path="/*" element={<AllPages />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
