import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaUserAlt,
  FaProjectDiagram,
  FaPhoneAlt,
  FaSignInAlt,
  FaBars,
  FaTimes,
} from "react-icons/fa"; // Added the hamburger and close icons
import "../assets/css/navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState); // Toggle the menu visibility
  };

  return (
    <div className="navbar-container-main">
      <div className="logo-container">
        <div className="brand-name">
          <Link to="/">Kashif Naxis</Link>
        </div>
      </div>
      <div className={`navbar-wrapper`}>
        <nav className={`navbar-links-container ${isOpen ? "open" : ""}`}>
          <div className="navbar-menu-toggle" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}{" "}
            {/* Toggle between hamburger and close icons */}
          </div>
          <ul className={`navbar-menu-list ${isOpen ? "open" : ""}`}>
            <li>
              <Link to="/" className="navbar-link">
                <FaHome className="navbar-icon" /> Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="navbar-link">
                <FaUserAlt className="navbar-icon" /> About
              </Link>
            </li>
            <li>
              <Link to="/projects" className="navbar-link">
                <FaProjectDiagram className="navbar-icon" /> Projects
              </Link>
            </li>
            <li>
              <Link to="/contact" className="navbar-link">
                <FaPhoneAlt className="navbar-icon" /> Contact
              </Link>
            </li>
            <li className="navbar-sign-in">
              <Link to="/auth-page" className="navbar-sign-in-btn">
                <FaSignInAlt className="navbar-icon" /> Sign In
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
