// AllPages.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Project from "../pages/Project";
import About from "../pages/About";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";

const AllPages = () => {
  return (
    <Routes>
      <Route path="/projects" element={<Project />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AllPages; // Ensure this is exported as default
