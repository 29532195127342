import React, { useState } from "react";
import "../../assets/css/authpage.css";
import { FcGoogle } from "react-icons/fc";
import { FaUser, FaEye, FaEyeSlash, FaLock } from "react-icons/fa";

const AuthPage = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false); // Default to Sign In view
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleGoogleSignIn = () => {
    // Google sign-in logic
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission for Sign In/Sign Up
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="auth-container">
      <div className="auth-form-container">
        <h1 className="auth-title">{isSignUp ? "Sign Up" : "Sign In"}</h1>
        <form onSubmit={handleSubmit} className="auth-form">
          {isSignUp && (
            <div className="auth-form-group">
              <FaUser className="auth-icon" />
              <input
                type="text"
                name="fullName"
                id="fullName"
                placeholder=" "
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                className="auth-input"
              />
              <label htmlFor="fullName" className="auth-label">
                Full Name
              </label>
            </div>
          )}

          <div className="auth-form-group">
            <FaUser className="auth-icon" />
            <input
              type="email"
              name="email"
              id="email"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="email" className="auth-label">
              Email
            </label>
          </div>

          <div className="auth-form-group password-field">
            <FaLock className="auth-icon" />
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              id="password"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="auth-input"
            />
            <label htmlFor="password" className="auth-label">
              Password
            </label>
            <div
              className="auth-password-toggle"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          <button type="submit" className="auth-btn">
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>

          <div className="auth-social-buttons">
            <button onClick={handleGoogleSignIn} className="auth-btn-google">
              <FcGoogle className="google-icon" /> Sign in with Google
            </button>
          </div>

          <div className="auth-switch">
            <p
              onClick={() => setIsSignUp(!isSignUp)}
              className="auth-toggle"
            >
              {isSignUp
                ? "Already have an account? Sign In"
                : "Don't have an account? Sign Up"}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthPage;
